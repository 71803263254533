import "../css/header.css";

function Header() {
  return (
    <>
      <div className="wrapper">
        <div className="fg">Football Fortune Seekers</div>
        <div className="bg">Football Fortune Seekers</div>
      </div>
    </>
  );
}

export default Header;
