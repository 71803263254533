import Container from "react-bootstrap/esm/Container";

function Footer() {
  return (
    <>
      <Container>
        <br />
        <br />
      </Container>
    </>
  );
}

export default Footer;
